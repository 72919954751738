import React from 'react';
import IndustryDataTypeTemplate from '@components/IndustryDataType/IndustryDataTypeTemplate';
import { dataTypes, industries } from '@constants/industriesAndDatatypes';
import BackgroundImage from './assets/Financial_Cover.jpg';
import metaImage from './assets/meta.jpg';

const FinancialPage: React.FC = () => {
  return (
    <IndustryDataTypeTemplate
      pageName="industry-financial"
      helmet={{
        title: 'TileDB for Financial Services',
        description: 'Use TileDB to unleash insights with real-time, fundamental and alternative data sets.',
        shareImage: {
          url: metaImage,
          width: 1200,
          height: 627,
        },
      }}
      header="Financial Services"
      title="Unleash insights with real-time, fundamental and alternative datasets"
      heroImage={BackgroundImage}
      icon={industries.finance.icon}
      overview={
        <>
          <p>
            Financial institutions of all sizes and types are embracing cloud for advanced analytics. Historical ticker data,
            crypto-transaction history, geolocation clustering analysis, alternative data analysis - the sky’s the limit.
          </p>
          <p>
            Leading-edge quants and enterprise analytics professionals are turning to TileDB to evolve their data infrastructures, deliver
            disruptive innovations and launch simpler and secure fintech marketplaces.
          </p>
        </>
      }
      relatedItems={[dataTypes.dataframes, dataTypes.timeSeries, dataTypes.financialData, dataTypes.opticalImaging, dataTypes.weather]}
    />
  );
};

export default FinancialPage;
